.title {
	font-family: Times, Times New Roman, serif;
	margin-left: 10px; 
	margin-top: 20px; 
	color: #2556A6;
	font-weight: bold;
}

.content {
	font-family: Times, Times New Roman, serif;
	color: #203354; 
}
